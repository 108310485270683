var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.libraryProblems,
      "footer-props": _vm.footerOptions,
      "search": _vm.search
    },
    on: {
      "update:footerProps": function updateFooterProps($event) {
        _vm.footerOptions = $event;
      },
      "update:footer-props": function updateFooterProps($event) {
        _vm.footerOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_c('router-link', {
          staticClass: "ml-1 problem_link",
          attrs: {
            "to": '/problems/' + item.getUrl()
          }
        }, [_c('v-col', [_vm._v(" " + _vm._s(item.getTitle()) + " ")])], 1)], 1), _c('td', [_vm._v(" " + _vm._s(_vm.getDifficulty(item)) + " ")]), _c('td', [_vm._v(" Algorithm ")]), _c('td', _vm._l(_vm.getTags(item), function (t) {
          return _c('v-chip', {
            key: t,
            staticClass: "mr-2",
            attrs: {
              "outlined": "",
              "color": "grey lighten-2",
              "text-color": "grey",
              "label": ""
            },
            on: {
              "click": _vm.searchTag
            }
          }, [_vm._v(" " + _vm._s(t) + " ")]);
        }), 1), _vm.showAddToTest ? _c('td', [_c('problem-card', {
          attrs: {
            "problem": item,
            "selectedProblems": _vm.selectedProblems
          }
        })], 1) : _vm._e()])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }