var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.isAlreadyAdded ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "accent",
      "prominent": "",
      "outlined": "",
      "loading": _vm.addToTestLoading
    },
    on: {
      "click": function click($event) {
        _vm.addProblem(_vm.problem.getId());
      }
    },
    scopedSlots: _vm._u([{
      key: "loader",
      fn: function fn() {
        return [_c('span', [_vm._v("Adding ...")])];
      },
      proxy: true
    }], null, false, 1124057880)
  }, [_vm._v(" Add To Test ")]) : _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "warning",
      "prominent": "",
      "outlined": "",
      "loading": _vm.removeFromTestLoading
    },
    on: {
      "click": function click($event) {
        _vm.removeSelectedProblem(_vm.problem.getId());
      }
    },
    scopedSlots: _vm._u([{
      key: "loader",
      fn: function fn() {
        return [_c('span', [_vm._v("Removing ...")])];
      },
      proxy: true
    }])
  }, [_vm._v(" Remove from Test ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }