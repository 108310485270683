var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card', {
    staticClass: "px-2",
    attrs: {
      "outlined": "",
      "height": 75
    }
  }, [_c('v-row', {
    staticClass: "pt-2",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": 4,
      "lg": 3
    }
  }, [_c('v-text-field', {
    attrs: {
      "append-icon": _vm.mdiMagnify,
      "label": _vm.live ? 'Search' : 'Search (title, tags)',
      "clearable": "",
      "single-line": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.problemsObject,
      "footer-props": _vm.footerOptions,
      "search": _vm.search
    },
    on: {
      "update:footerProps": function updateFooterProps($event) {
        _vm.footerOptions = $event;
      },
      "update:footer-props": function updateFooterProps($event) {
        _vm.footerOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('tr', [_c('td', [_c('router-link', {
          staticClass: "ml-1 problem_link",
          attrs: {
            "to": _vm.urlPrefix + '/problems/' + item.contentPreview.url
          }
        }, [_vm._v(" " + _vm._s(item.contentPreview.title) + " ")])], 1), _c('td', [_vm._v("Algorithms")]), _c('td', [_c('question-score', {
          attrs: {
            "id": item.question.questionId,
            "score": item.question.score
          }
        })], 1), _c('td', [_c('v-icon', {
          on: {
            "click": function click($event) {
              return _vm.deleteProblem(item.contentPreview);
            }
          }
        }, [_vm._v(_vm._s(_vm.mdiDelete))])], 1)])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }