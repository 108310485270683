<template>
<v-row>
	<v-col>
		<v-row class="mt-2 mb-2 d-flex">
			<v-col :col="3" class="d-flex justify-end">
				<v-dialog transition="dialog-bottom-transition" v-model="dialog" @keydown.esc="dialog = false">
					<template v-slot:activator="{ on }">
						<v-btn color="accent" height="35" v-on="on">
							<v-icon class="mr-2" small>{{mdiPlus}}</v-icon>
							Add MCQ Question
						</v-btn>
					</template>
					<v-card ref="libraryDialog" raised width="700px">
						<v-card-text class="pt-5">
							<v-row>
								<v-col>
									<v-row>
										<v-col>
											<span class="text-h7">
												Question statement (in markdown)
											</span>
											<MarkdownEditor
												:editorialMode="false"
												:submissionMode="true"
												:code="statement"
												:onCodeChange="onStatementChange"
												:value="editorConfig"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col class="d-flex">
											<div class="title"> Options </div>
											<v-btn @click="addOption" color="info" class="ml-5">Add Option</v-btn>
										</v-col>
									</v-row>
									<v-form ref="mcqAdd" v-model="mcqAddValid">
										<v-row v-for="(opt, idx) in options" :key="idx">
											<v-col :cols="8" class="mcq_option">
												<MarkdownEditor
													:editorialMode="false"
													:submissionMode="true"
													:code="opt.statement"
													:onCodeChange="v => optionStatement(idx, v)"
													:value="editorConfig"
												/>
											</v-col>
											<v-col :cols="4" class="">
												<v-switch
													label="Correct Answer"
													v-model="options[idx].isAnswer" 
													@change="v => setCorrectAnswer(v, idx)"
													:rules="singleOptionCorrectRule"
												>
												</v-switch>
												<v-btn small class="ml-5" @click="deleteOption(idx)" color="error"
													:disabled="!(options.length > 2)">
													Delete
												</v-btn>
											</v-col>
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-card-text>
						<v-card-actions>
							<v-btn color="accent" @click="create" :loading="creating" :disabled="!mcqAddValid">
								Create
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-col>
		</v-row>
	</v-col>
</v-row>
</template>

<script>
import { mdiPlus} from '@mdi/js';
import MarkdownEditor from "../../components/MarkdownEditor.vue";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
	props: {},
		data() {
		return {
			creating: false,
			dialog: false,
			mdiPlus: mdiPlus,
			title: "This is title",
			statement: "Statement",
			mcqAddValid: false,
			options: [
				{
					statement: "Option 1",
					isAnswer: false
				},
				{
					statement: "Option 2",
					isAnswer: false
				},
			],
			singleOptionCorrectRule: [
				v => this.options.filter(op => op.isAnswer === true).length == 1 || 'Only one correct option',
			]
		}
	},
	components: {
		MarkdownEditor,
	},
	computed: {
		...mapState("content", ["content"]),
	},
	methods: {
	...mapActions("content", ["updateContent", "publishContent"]),
	...mapMutations("content", ["addMcq"]),
	onStatementChange(s) {
		this.statement = s;
    },
	addOption(){
		this.options.push({
			statement: `Option ${this.options.length + 1}`,
			isAnswer: false
		})
		this.$refs.mcqAdd.resetValidation();
	},
	deleteOption(idx){
		if (this.options.length > 2){
			this.options.splice(idx, 1);
		}
		this.$refs.mcqAdd.resetValidation();
	},
	setCorrectAnswer(v, idx){
		this.options[idx].isAnswer = v;
		this.$refs.mcqAdd.validate();
	},
    editorConfig(newConfig) {
      console.log("Change ", newConfig);
    },
    onCmCodeChange(newCode) {
      // this.setCode(newCode);
    },
		optionStatement(idx, statement) {
			this.options[idx].statement = statement;
		},
		setTitle(title) {
			console.log(title);
		},
		create() {
			console.log(this.statement, this.options);
			this.creating = true;
			this.addMcq({statement: this.statement, options: this.options});
			this.updateContent({"autoPublish": true}).then(__ => {
					this.creating = false;
					this.dialog = false;
					// reset after save
					this.options = [];
					[0, 1].forEach((op, idx) => {
						this.statement = "Statement";
						this.options.push({isAnswer: false, statement: `Option ${idx+1}`});
					});
					this.$refs.mcqAdd.resetValidation();
					console.log('setting defaults post creation', this.options);
			});
		},
	}
}
</script>
