<template>
    <v-card>
      <v-card outlined :height="75" class="px-2">
        <v-row no-gutters class="pt-2">
            <v-col :cols="4" :lg="3">
                <v-text-field v-model="search" :append-icon="mdiMagnify" :label="live? 'Search': 'Search (title, tags)'" clearable single-line outlined></v-text-field>
            </v-col>
        </v-row>
      </v-card>
      <v-data-table :headers=headers :items=problemsObject :footer-props.sync=footerOptions :search="search">
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <!-- <div class="dot" :class="getStatusColor(item)" /> -->
              <router-link class="ml-1 problem_link" :to="urlPrefix + '/problems/'+item.contentPreview.url">  {{ item.contentPreview.title }} </router-link>
            </td>
						<td>Algorithms</td>
						<td><question-score :id="item.question.questionId" :score="item.question.score"></question-score></td>
						<td>
							<v-icon @click="deleteProblem(item.contentPreview)">{{mdiDelete}}</v-icon>
						</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import { getters, mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { mdiDelete, mdiMagnify } from '@mdi/js'
import QuestionScore from "./QuestionScore";

export default {
  props: {
    "problems": {
      type: Array,
      required: true,
    },
    "autopick": {
      type: Boolean,
      required: false,
      default: false,
    },
    "live": {
      type: Boolean,
      required: false,
      default: false,
    },
    "urlPrefix": {
      type: String,
      required: false,
      default: "",
    },
  },
  data: function() {
    return {
      mdiMagnify: mdiMagnify,
      mdiDelete: mdiDelete,
      hideSolved: false,
      footerOptions: {
        itemsPerPageOptions: [25, 50, 100, -1],
      },
      search: null,
			items: [
        { title: 'Remove' },
      ],
    };
  },
  computed: {
		...mapState("content", ["updateContentStatus", "content"]),
    headers() {
      var h = [
        {
          text: "Title",
          align: "left",
          sortable: true,
          value: "title",
        },
        {
          text: "Skills",
          align: "left",
          sortable: true,
          value: "metaData.problemMeta.problemStats.accuracy"
        },
        {
					text: "Score",
					align: "left",
          value: "metaData.problemMeta.problemStats.score"
				},
				{
					text: "Action",
					align: "left",
				},
      ];
      return h;
    },
    problemsObject() { // Needed because of https://github.com/vuetifyjs/vuetify/issues/7066
      return this.problems && this.problems
        .filter(p => !this.hideSolved || p.getStatus() != 13)
        .map(p => {
          return {
            contentPreview: p.contentPreview.toObject(),
            question: p.question.toObject(),
          };
        });
    },
  },
  methods: {
		...mapMutations("content", ["setTestProblems", "removeProblem"]),
		...mapActions("content", ["updateContent", "publishContent"]),
    getStatusClasses(p) {
      if(!p.status) return "grey lighten-2";
      else if(p.status == 13) return "green lighten-2";
      else return "red lighten-2";
    },
		deleteProblem(problem) {
			this.removeProblem(problem.id);
			this.updateContent({"autoPublish": true});
		},
  },
  mounted() {
    if(this.autopick) {
      this.pickAProblem();
    }
  },
  components: {
    QuestionScore,
  },
};
</script>
<style scoped>
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .02);
 }

.problem_link {
    text-decoration: none;
    color: black;
}

.v-data-table td {
    height: 64px;
}
</style>
