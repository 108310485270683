<template>
	<v-row>
		<v-col>
			<v-card>
				<v-data-table :headers=headers :items="userActivityRowObjects">
					<template v-slot:item="{item}">
						<tr class="px-0 py-0" @click="open(item)">
							<td>
								{{item.name}}
							</td>
							<td>
								{{item.email}}
							</td>
							<td>
								{{item.status}}
							</td>
							<td>
								{{item.score == -1? '-': item.score}}
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import {mapGetters } from "vuex";

export default {
	props: {
		allContentUserActivity: {
			type: Array,
			required: true,
		},
		url: {
			type: String,
			required: true,
		}
	},
	data: function() {
		return {
				headers: [
				{
					text: "Name",
					align: "left",
					value: "name",
				},
				{
					text: "Email",
					align: "left",
					value: "email"
				},
				{
					text: "Status",
					align: "left",
					value: "status"
				},
				{
					text: "Score",
					align: "left",
					value: "score"
				},
			]
		}
	},
	computed: {
    ...mapGetters("content", ["statusKeys"]),
		userActivityRowObjects() {
			return this.allContentUserActivity.map(p => {
				var o = p.toObject();
				o.status = this.getStatus(o);
				o.score = this.hasEnded(o)? o.data.testReport.overallScore: -1;
				o.name = o.data.testReport.userName || "Unnamed"
				return o;
			});
		},
	},
	methods: {
		status(statusNumber) {
      var retStatus = {};
      console.log("statusKeys", this.statusKeys);
      for(var key in this.statusKeys) {
        retStatus[this.statusKeys[key]] = key;
      }
      return retStatus[statusNumber];
    },
		open(item) {
			this.$router.push("/hiring/tests/" + this.url + "/reports/" + item.userId);
		},
		getStatus(activity) {
			var testReport = activity.data.testReport;
			if (testReport.startedAt == 0) return "Invited";
			if (testReport.endedAt == 0) return "Currently taking";
			return "Completed";
		},
		hasEnded(activity) {
			return activity.data.testReport.endedAt !== 0;
		}
	}

}
</script>
<style scoped>
	.dot {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		display: inline-block;
	}

	tbody tr:nth-of-type(odd) {
		background-color: rgba(0, 0, 0, .02);
	}

	.user_link {
			text-decoration: none;
			color: black;
	}

	.v-data-table td {
			height: 64px;
	}

	tr:hover {
		cursor: pointer;
	}
</style>
