var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.edit,
      expression: "!edit"
    }],
    staticClass: "shrink",
    staticStyle: {
      "width": "65px"
    },
    attrs: {
      "append-icon": _vm.mdiPencil,
      "readonly": "",
      "value": _vm.score
    },
    on: {
      "click:append": _vm.editScore
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.edit,
      expression: "edit"
    }],
    ref: "editValue",
    staticClass: "shrink",
    staticStyle: {
      "width": "65px"
    },
    attrs: {
      "autofocus": "",
      "append-icon": _vm.mdiCheck,
      "loading": _vm.loading
    },
    on: {
      "click:append": _vm.saveScore
    },
    model: {
      value: _vm.newScore,
      callback: function callback($$v) {
        _vm.newScore = $$v;
      },
      expression: "newScore"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }