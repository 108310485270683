var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-card', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.userActivityRowObjects
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('tr', {
          staticClass: "px-0 py-0",
          on: {
            "click": function click($event) {
              return _vm.open(item);
            }
          }
        }, [_c('td', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.email) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.status) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.score == -1 ? '-' : item.score) + " ")])])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }